import { uniqueId } from 'lodash-es';
import { SelectProjectProduct, SelectProductionOrderProduct } from '@/views/dialogs';
import {
  BackendResource,
  ProductionOrderChangeDetailResource,
  ProductionOrderChangeResource,
  ProductionOrderProductResource
} from '@/resource/model';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';

import { Component, PropSync, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { ChangeTypeEnum } from '@/resource/enum/changeType';
import { translation } from '@/utils';
import productionOrderProductService from '@/api/production-management/production-order-product';

@Component({
  name: 'order-change-detail-table',
  components: {
    SelectProjectProduct,
    SelectProductionOrderProduct
  }
})
export default class OrderChangeDetailTable extends mixins(PagingMixin) {
  public $table!: OsTable;

  /**
   * 项目产品弹窗显示控制
   */
  public projectProductDialogVisible = false;

  /**
   * 生产订单弹窗显示控制
   */
  public orderProductDialogVisible = false;

  /**
   * 变更单数据对象
   */
  @PropSync('data', { required: true })
  public orderChange!: ProductionOrderChangeResource;

  /**
   * 可选产品列表
   */
  public productList: Array<ProductionOrderProductResource> = [];

  /**
   * 可选后道工艺列表
   */
  public backendCraftsList: Array<BackendResource> = [];

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderChangeDetailResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    rowKey: (): string => {
      return 'uuid';
    }
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'addProduct',
      slot: 'start',
      label: 'orderChange.addProduct',
      type: 'primary',
      icon: 'el-icon-plus',
      permissionCode: '',
      handleClick: (): void => {
        this.addNewProduct();
      }
    },
    {
      operationType: 'add',
      slot: 'start',
      label: 'orderChange.editProduct',
      type: 'primary',
      icon: 'el-icon-plus',
      permissionCode: '',
      handleClick: (): void => {
        this.addOrderProduct();
      }
    },
    {
      operationType: 'delete',
      slot: 'start',
      label: 'button.delete',
      icon: 'el-icon-delete',
      plain: true,
      type: 'danger',
      disabled: true,
      permissionCode: '',
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderChangeDetailResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'changeType',
      label: 'orderChangeDetail.changeType',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return translation(`changeType.${ChangeTypeEnum[(rowData as ProductionOrderChangeDetailResource).changeType]}`);
      }
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '220px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleSize',
      label: 'orderProduct.visibleSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'completeSize',
      label: 'orderProduct.completeSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressFlag',
      label: 'orderProduct.prepressFlag',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'printingFlag',
      label: 'orderProduct.printingFlag',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendFlag',
      label: 'orderProduct.backendFlag',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderChangeDetailResource> = [];

  /**
   * 编辑行
   */
  private editRow: ProductionOrderChangeDetailResource | null = null;

  public created(): void {
    this.tableOption.data = this.orderChange.changeLog;
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<ProductionOrderChangeDetailResource>): void {
    const allowOptions = ['delete'];
    this.tableToolbarOptions.forEach(option => {
      if (allowOptions.indexOf(option.operationType) > -1) {
        option.disabled = value.length === 0;
      }
    });
  }

  /**
   * hack 表格渲染问题
   */
  public handleChange(): void {
    this.tableOption.data.splice(0, 0);
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderChangeDetailResource>): void {
    this.selectedRows = selectedData;
  }

  public getChangeTypeI18Key(type: ChangeTypeEnum): string {
    return 'changeType.' + ChangeTypeEnum[type];
  }

  public getSelectableTypeList(): Array<{ label: string; value: number; disabled: boolean }> {
    const list = [];
    for (const key in ChangeTypeEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getChangeTypeI18Key(Number(key))),
          value: Number(key),
          disabled: Number(key) === 2
        });
      }
    }

    return list;
  }

  /**
   * 动态获取供应商产品列表
   * @param rowData 行数据
   * @returns 供应商产品列表
   */
  public async getSupplierProductList(rowData: ProductionOrderChangeDetailResource): Promise<void> {
    const query = {
      platformProductId: rowData.platformProductId,
      supplierId: this.orderChange.supplierId
    };

    const supplierProductList = await productionOrderProductService.listEnableByPlatformProduct(query as any);

    rowData.supplierProductList = supplierProductList;
  }

  /**
   * 从项目新增产品
   */
  public addNewProduct(): void {
    this.projectProductDialogVisible = true;
  }

  /**
   * 从订单新增产品
   */
  public addOrderProduct(): void {
    this.orderProductDialogVisible = true;
  }

  /**
   * 选中项目产品回调
   * @param productList 选中产品
   */
  public handleSelectedProjectProduct(productList: Array<ProductionOrderProductResource>): void {
    productList.forEach(product => {
      if (this.isProductPicked(product.itemCode)) {
        return;
      }

      const newLog = { uuid: this.createUuid(), changeType: ChangeTypeEnum.add, ...product };
      newLog.orderItemId = product.itemId;

      this.tableOption.data.push(newLog as ProductionOrderChangeDetailResource);

      this.orderChange.changeLog.splice(0, 0);
    });
  }

  /**
   * 选中生产订单产品回调
   * @param productList 产品列表
   */
  public async handleSelectedProductionOrderProduct(productList: Array<Partial<ProductionOrderProductResource>>): Promise<void> {
    productList.forEach(product => {
      if (this.isProductPicked(product.itemCode!)) {
        return;
      }

      if (product.backendCrafts) {
        product.backendCraftsList = product.backendCrafts.split(',');
      }

      delete product.id;
      this.tableOption.data.push({
        uuid: this.createUuid(),
        changeType: ChangeTypeEnum.stop,
        ...product
      } as ProductionOrderChangeDetailResource);
      this.orderChange.changeLog.splice(0, 0);
    });
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    const uuids = this.selectedRows.map(x => x.uuid);
    const newList = this.orderChange.changeLog.filter(x => !uuids.includes(x.uuid));

    this.tableOption.data = this.orderChange.changeLog = newList;
    (this.$refs.tableRef as OsTable).clearSelection();
  }

  /**
   * 判断项次是否已选择
   * @param itemId 项次ID
   */
  private isProductPicked(itemCode: string): ProductionOrderChangeDetailResource | undefined {
    return this.tableOption.data.find(item => item.itemCode === itemCode);
  }

  private createUuid(): string {
    return uniqueId('orderChange');
  }
}
