import { productionOrderService, productionOrderChangeService } from '@/api';
import { ChangeTypeEnum } from '@/resource/enum/changeType';
import {
  ProductionOrderResource,
  ProductionOrderChangeResource,
  ProductionOrderChangeDetailResource
} from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { uniqueId } from 'lodash-es';
import { Component, Vue } from 'vue-property-decorator';
import OrderChangeDetailTable from './order-change-detail-table/order-change-detail-table.vue';
@Component({
  components: {
    OrderChangeDetailTable
  }
})
export default class AddProductionOrderChange extends Vue {
  /**
   * 页面类型 新建/编辑
   */
  public title = '';
  /**
   * 页面loading标记
   */
  public pageLoading = false;

  /**
   * 新建/编辑标识, 默认为新建
   */
  public isNew = true;

  /**
   * 保存类型 1.保存 2.保存并提交
   */
  public saveType = 1;

  /**
   * 变更单数据对象
   */
  public orderChange: ProductionOrderChangeResource & any = {};

  /**
   * 变更单提交表单
   */
  public formData: {
    orderId: number | null;
    projectId: number | null;
    supplierId: number | null;
    id: number | null;
    changeReason: string;
    changeLog: Array<ProductionOrderChangeDetailResource>;
  } = {
    orderId: Number(this.$route.query.orderId),
    projectId: null,
    supplierId: null,
    id: Number(this.$route.query.id),
    changeReason: '',
    changeLog: []
  };

  /**
   * 表单验证
   */
  public formRules = {
    changeReason: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.inputChangeReason')));
            return;
          }
          callback();
        }
      }
    ]
  };

  /**
   * vue instance created hook
   */
  public created(): void {
    if (this.formData.id) {
      this.isNew = false;
      this.title = translation('orderChange.edit');
      this.initOrderChange();
    } else {
      this.isNew = true;
      this.title = translation('orderChange.create');
      this.initOrder();
    }
  }

  /**
   * 初始化订单信息
   */
  public async initOrder(): Promise<void> {
    if (!this.formData.orderId) {
      throw new Error('orderId must not be null');
    }

    try {
      const res: ProductionOrderResource = await productionOrderService.getById(this.formData.orderId);
      this.orderChange = Object.assign(res);
      this.orderChange.orderCode = res.code;
      this.orderChange.orderId = res.id;
      this.orderChange.id = null;
      this.orderChange.code = null;
      this.formData.projectId = Number(res.projectId);
      this.formData.supplierId = Number(res.supplierId);
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 初始化变更单信息
   */
  public async initOrderChange(): Promise<void> {
    if (!this.formData.id) {
      throw new Error('id must not be null');
    }

    try {
      const data: ProductionOrderChangeResource = await productionOrderChangeService.getById(this.formData.id);

      data.orderChangeItemVOList.forEach(item => {
        item.uuid = this.createUuid();
        item.itemCode = item.orderItemCode;
        item.itemId = item.orderItemId;
        item.changeType = item.type;
      });

      this.orderChange = data;
      this.formData.orderId = data.orderId;
      this.formData.projectId = data.projectId;
      this.formData.changeReason = data.reason;
      this.formData.changeLog = data.orderChangeItemVOList;
      this.formData.supplierId = Number(data.supplierId);
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 返回
   */
  public goBack(): void {
    this.$router.go(-1);
  }

  /**
   * 保存
   */
  public handleSave(): void {
    this.saveType = 1;
    this.submit();
  }

  /**
   * 保存并提交
   */
  public handleSaveAndSubmit(): void {
    this.saveType = 2;
    this.submit();
  }

  /**
   * 数据校验
   */
  private validData(): boolean {
    if (this.formData.changeLog.length <= 0) {
      Message.warning(translation('orderChange.noDataSubmitWarning'));
      return false;
    }

    for (const item of this.formData.changeLog) {
      if (item.changeType !== ChangeTypeEnum.stop) {
        if (item.count <= 0) {
          if (!item.count) {
            Message.warning(translation('orderChange.productCountInputTip'));
            return false;
          }
        }

        if (!item.requiredDeliveryTime) {
          Message.warning(translation('orderChange.requiredDeliveryTimeInputTip'));
          return false;
        }

        if (!item.requiredArriveTime) {
          Message.warning(translation('orderChange.requiredArriveTimeInputTip'));
          return false;
        }
      }
    }

    return true;
  }

  /**
   * 提交
   */
  private submit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      const validDataResult: boolean = this.validData();
      if (!validDataResult) return;

      const data = {
        id: this.formData.id,
        orderId: this.formData.orderId,
        reason: this.formData.changeReason,
        orderChangeItemList: this.assembleChangeLogList()
      };

      this.pageLoading = true;
      try {
        if (this.isNew) {
          if (this.saveType === 1) {
            await productionOrderChangeService.post(data as any);
          } else {
            await productionOrderChangeService.postAndSubmit(data as any);
          }
        } else {
          await productionOrderChangeService.put(data as any);
        }

        this.$router.push({
          path: '/production-order-change'
        });
      } catch (error) {
        messageError(error);
      } finally {
        this.pageLoading = false;
      }
    });
  }

  /**
   * 组装变更明细数据
   * @returns 变更明细
   */
  private assembleChangeLogList(): Array<any> {
    const changeLogList = [];
    for (const item of this.formData.changeLog) {
      const changeLog = {
        orderChangeDetailId: item.id,
        itemId: item.orderItemId,
        type: item.changeType,
        count: item.count,
        requiredArriveTime: item.requiredArriveTime,
        requiredDeliveryTime: item.requiredDeliveryTime
      };

      changeLogList.push(changeLog);
    }

    return changeLogList;
  }

  private createUuid(): string {
    return uniqueId('orderChange');
  }
}
